import React from 'react';
import Styles from './SettlementList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { DealBillingContext } from '~/models/DealBilling';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useDeals } from '~/hooks/useDeals';

type Props = {
  constructionContext: DealBillingContext;
  target: 'owner' | 'rentee';
};

/**
 * 精算項目テーブル原状回復・修繕費用出力行
 *
 * @for clients
 */

export const SettlementListRestorationAmountRow: React.FC<Props> = ({
  constructionContext,
  target
}: Props) => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const expensesLabel = (): string => {
    if (deal?.dealType === 'restoration') return '原状回復費用';
    if (deal?.dealType === 'repair') return '修繕費用';
    return '施工費用';
  };
  const {
    sumOwnerAmount,
    sumOwnerAmountWithTax,
    sumRenteeAmount,
    sumRenteeAmountWithTax
  } = constructionContext;
  const isOwner = target === 'owner';
  const totalAmount = isOwner ? sumOwnerAmount : sumRenteeAmount;
  const totalAmountWithTax = isOwner
    ? sumOwnerAmountWithTax
    : sumRenteeAmountWithTax;
  return (
    <tr className={Styles.ListRow}>
      <td className={Styles.OrderHandle} />
      <td className={Styles.Settlement}>{expensesLabel()}</td>
      <td className={Styles.NonTaxAmount}>
        ¥{toThousandSeparator(totalAmount)}
      </td>
      <td className={Styles.TaxRate}>10%</td>
      <td className={Styles.TaxAmount}>
        ¥{toThousandSeparator(totalAmountWithTax)}
      </td>
      <td className={Styles.Edit} />
    </tr>
  );
};

import React from 'react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalBody, ModalHeader, Modalv2 } from '@itandi/itandi-bb-ui';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';
import { SettlementForm } from '../SettlementForm';
import { useSettlementItems } from '~/hooks/useSettlementItems';
import {
  DealSettlement,
  DealSettlementEdit,
  validationSchemaSettlement
} from '~/models/DealSettlement';

type Props = {
  dealSettlement: DealSettlement;
  handleMutateSettlements: () => Promise<void>;
  handleClose: () => void;
  handleUpdate: (
    submitValue: DealSettlementEdit,
    estimationId: number
  ) => Promise<NskResponse<void>>;
};

export const SettlementEditModal: React.FC<Props> = ({
  dealSettlement,
  handleMutateSettlements,
  handleClose,
  handleUpdate
}: Props) => {
  const { data: settlementItems } = useSettlementItems().index();
  const methods = useForm<DealSettlementEdit>({
    defaultValues: dealSettlement,
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaSettlement)
  });
  const onClickSubmit = React.useCallback(() => {
    methods.handleSubmit(async () => {
      const response = await handleUpdate(
        methods.getValues(),
        dealSettlement.id
      );
      if (isSuccess(response)) {
        await handleMutateSettlements();
        methods.reset();
        handleClose();
        toast.success('更新しました');
        return;
      }
      toast.error('エラーが発生しました');
      handleClose();
      methods.reset();
    })();
  }, [
    dealSettlement.id,
    handleClose,
    handleMutateSettlements,
    handleUpdate,
    methods
  ]);
  if (settlementItems === null) return null;
  return (
    <Modalv2 handleClose={handleClose} isOpen>
      <ModalHeader title="精算項目の修正" />
      <ModalBody>
        <FormProvider {...methods}>
          <SettlementForm
            onClickSubmit={onClickSubmit}
            settlementItems={settlementItems}
            type="edit"
          />
        </FormProvider>
      </ModalBody>
    </Modalv2>
  );
};

import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { Alert, Grid } from '@itandi/itandi-bb-ui';
import Styles from './ChangeAmountModal.module.scss';
import { Modal } from '~/components/common/parts/modal/Modal';
import { ChangeAmountModalButtons } from '~/components/common/constructions/change_amount/ChangeAmountModalButtons';
import {
  BillingBulkUpdateContext,
  BillingType,
  buildDefaultContext,
  DealBillingContext,
  validationSchemeBulkUpdateContext
} from '~/models/DealBilling';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { postUpdate } from '~/utils/api/deals/DealBillingUpdate';
import { isSuccess } from '~/utils/api/api_handler';
import { useDealBillings } from '~/hooks/deals/useDealBillings';
import { useSettlementItems } from '~/hooks/useSettlementItems';
import { useUsingApplications } from '~/hooks/useUsingApplications';

type Props = {
  billingContext: DealBillingContext;
  handleClose: () => void;
  billingType: BillingType;
};

/**
 * 単価の一括変更モーダル
 * @param handleClose
 * @param billingType
 * @constructor
 * @for client
 */
export const ChangeAmountModal: React.FC<Props> = ({
  billingContext,
  handleClose,
  billingType
}: Props) => {
  const router = useRouter();
  const { id = null } = router.query;
  const { mutate: mutateBillings } = useDealBillings().index(
    id === null ? null : Number(id),
    billingType
  );
  const { useSK } = useUsingApplications();
  const { data: settlementItems } = useSettlementItems().index();
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [context, setContext] = React.useState<BillingBulkUpdateContext | null>(
    null
  );
  const handleAcceptUpdate = async (): Promise<void> => {
    const result = await postUpdate(context, Number(id), billingType);
    if (isSuccess(result)) {
      mutateBillings && (await mutateBillings());
      setShowConfirm(false);
      handleClose();
      toast.success('単価を変更しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  if (settlementItems === null) return null;
  return (
    <Modal handleClose={handleClose} isOpen title="施工単価の一括変更">
      <Grid gap="8px">
        <Alert variant="info">各施工項目の単価を一括で変更します。</Alert>
        {settlementItems.length > 0 &&
          !useSK &&
          billingContext.dealSettlements.length > 0 && (
            <Alert variant="warning">精算項目には反映されません。</Alert>
          )}
      </Grid>
      <Formik
        initialValues={buildDefaultContext()}
        onSubmit={(submitValue: BillingBulkUpdateContext): void => {
          setContext(submitValue);
          setShowConfirm(true);
        }}
        validationSchema={validationSchemeBulkUpdateContext}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          values,
          errors,
          touched
        }: FormikProps<BillingBulkUpdateContext>): ReactNode => (
          <div>
            <div className={Styles.FormContainer}>
              <div className={Styles.FormGroup}>
                <div className={Styles.FormLabel}>倍率</div>
                <div className={Styles.FormWrapper}>
                  <Form.Control
                    className={Styles.ScaleForm}
                    isInvalid={!!(touched.scale && errors.scale)}
                    name="scale"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onWheel={(e: React.WheelEvent<HTMLInputElement>): void => {
                      e.currentTarget.blur();
                    }}
                    type="number"
                    value={values.scale}
                  />
                  <span className={Styles.ScaleUnitLabel}>倍</span>
                </div>
                {errors.scale != null && (
                  <div className={Styles.InvalidFeedback}>{errors.scale}</div>
                )}
                <div className={Styles.Description}>
                  元の単価✕指定した倍率に変更します
                </div>
              </div>
              <div className={Styles.FormGroup}>
                <div className={Styles.FormLabel}>切り捨て設定</div>
                <Form.Control
                  as="select"
                  name="truncateScale"
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const { value } = e.target;
                    const valueNum = Number(value);
                    setFieldValue('truncateScale', valueNum);
                  }}
                  value={values.truncateScale}
                >
                  <option key={0} value={0}>
                    1円未満を切り捨て
                  </option>
                  <option key={1} value={1}>
                    10円未満を切り捨て
                  </option>
                  <option key={2} value={2}>
                    100円未満を切り捨て
                  </option>
                  <option key={3} value={3}>
                    1,000円未満を切り捨て
                  </option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.truncateScale}
                </Form.Control.Feedback>
              </div>
            </div>
            <ChangeAmountModalButtons
              handleAccept={handleSubmit}
              handleCancel={handleClose}
              submitInvalid={!isValid}
            />
          </div>
        )}
      </Formik>
      <ConfirmModal
        actionText="確定"
        confirmType="save"
        handleAccept={handleAcceptUpdate}
        handleCancel={(): void => setShowConfirm(false)}
        isOpen={showConfirm}
      >
        単価を一括変更してよろしいですか？
      </ConfirmModal>
    </Modal>
  );
};

import React from 'react';
import { InputSelect } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import {
  TriggerType,
  COMPANY_TRIGGER_TYPE_SELECT_OPTIONS,
  NotificationEdit,
  VENDOR_TRIGGER_TYPE_SELECT_OPTIONS,
  ONLY_IMMEDIATE_TRIGGER_TYPE,
  ONLY_ORIGIN_VENDOR_TRIGGER_TYPE,
  ONLY_SUB_VENDOR_TRIGGER_TYPE
} from '~/models/Notification';
import Styles from '../NotificationModal.module.scss';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
}>;

export const TriggerTypeSelect: React.FC<Props> = ({ type }) => {
  const { register, formState, setValue } = useFormContext<NotificationEdit>();
  const { errors } = formState;
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const triggerType = e.target.value as TriggerType;

    if (ONLY_IMMEDIATE_TRIGGER_TYPE.includes(triggerType)) {
      setValue('notifyType', 'immediate');
    }
    if (ONLY_ORIGIN_VENDOR_TRIGGER_TYPE.includes(triggerType)) {
      setValue('receiveType', 'origin_deal_vendor_company');
    } else if (ONLY_SUB_VENDOR_TRIGGER_TYPE.includes(triggerType)) {
      setValue('receiveType', 'sub_deal_vendor_company');
    }

    setValue('triggerType', triggerType);
  };
  return (
    <>
      <div>
        <InputSelect
          isInvalid={errors.triggerType != null}
          {...register('triggerType')}
          onChange={handleChange}
        >
          <option value="">選択なし</option>
          {type === 'CompanyNotification' && (
            <>
              {Object.keys(COMPANY_TRIGGER_TYPE_SELECT_OPTIONS).map((key) => (
                <option
                  key={COMPANY_TRIGGER_TYPE_SELECT_OPTIONS[key].value}
                  value={COMPANY_TRIGGER_TYPE_SELECT_OPTIONS[key].value}
                >
                  {COMPANY_TRIGGER_TYPE_SELECT_OPTIONS[key].label}
                </option>
              ))}
            </>
          )}
          {type === 'VendorNotification' && (
            <>
              {Object.keys(VENDOR_TRIGGER_TYPE_SELECT_OPTIONS).map((key) => (
                <option
                  key={VENDOR_TRIGGER_TYPE_SELECT_OPTIONS[key].value}
                  value={VENDOR_TRIGGER_TYPE_SELECT_OPTIONS[key].value}
                >
                  {VENDOR_TRIGGER_TYPE_SELECT_OPTIONS[key].label}
                </option>
              ))}
            </>
          )}
        </InputSelect>
      </div>
      {errors.triggerType != null && (
        <div className={Styles.InvalidFeedback}>
          {errors.triggerType.message}
        </div>
      )}
    </>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Input,
  Button,
  FormControl,
  InputSelect,
  Box,
  Flex,
  Grid,
  InputText,
  InputRadio
} from '@itandi/itandi-bb-ui';
import Styles from './SettlementForm.module.scss';
import { DealSettlementEdit } from '~/models/DealSettlement';
import { SettlementItem } from '~/models/SettlementItem';
import { OTHER_CONSTRUCTION_ITEM_ID } from '~/constants/DealConstructionConst';
import { toThousandSeparator } from '~/utils/CurrencySeparator';

type Props = {
  type: 'add' | 'edit';
  settlementItems: SettlementItem[];
  onClickSubmit: () => void;
};

export const SettlementForm: React.FC<Props> = ({
  type,
  settlementItems,
  onClickSubmit
}) => {
  const { register, formState, setValue, watch } =
    useFormContext<DealSettlementEdit>();
  const { errors } = formState;
  const settlementItem = watch('settlementItem');
  const transactionType = watch('transactionType');
  const totalAmount = watch('totalAmount');
  const adjustTotalAmount = (
    transaction: 'billing' | 'refund',
    amount: number
  ): number =>
    transaction === 'billing' ? Math.abs(amount) : -Math.abs(amount);
  React.useEffect(() => {
    setValue('unitAmount', Number(totalAmount));
  }, [setValue, totalAmount]);
  const onChangeItem = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      if (settlementItems !== null) {
        const selectItem =
          settlementItems.find((i) => i.id === Number(e.target.value)) ?? null;
        setValue('settlementItem', selectItem);
      }
    },
    [settlementItems, setValue]
  );
  const handleChangeTransactionType = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      if (value === 'billing' || value === 'refund') {
        setValue('transactionType', value);
        setValue('totalAmount', adjustTotalAmount(value, Number(totalAmount)));
      }
    },
    [setValue, totalAmount]
  );
  return (
    <Box margin="8px">
      <Flex direction="column" gap="16px">
        <FormControl chip="必須" label="精算項目" variant="Vertical">
          <InputSelect
            fullWidth
            isInvalid={!!errors.settlementItem}
            onChange={onChangeItem}
            value={settlementItem?.id ?? -1}
          >
            <option key={-1} value={-1}>
              精算項目を選択
            </option>
            {settlementItems.map(
              (item: SettlementItem): React.ReactNode => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              )
            )}
          </InputSelect>
          {errors.settlementItem != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.settlementItem.message}
            </div>
          )}
        </FormControl>
        {settlementItem?.id === OTHER_CONSTRUCTION_ITEM_ID && (
          <FormControl chip="必須" label="精算項目名" variant="Vertical">
            <Input
              isInvalid={!!errors.otherName}
              placeholder="キャンペーン値引き"
              {...register('otherName')}
            />
          </FormControl>
        )}
        <FormControl chip="必須" label="取引区分" variant="Vertical">
          <Flex gap="16px">
            <InputRadio
              checked={transactionType === 'refund'}
              isInvalid={errors.transactionType != null}
              label="返還"
              onChange={handleChangeTransactionType}
              value="refund"
            />
            <InputRadio
              checked={transactionType === 'billing'}
              isInvalid={errors.transactionType != null}
              label="請求"
              onChange={handleChangeTransactionType}
              value="billing"
            />
          </Flex>
          {errors.transactionType != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.transactionType.message}
            </div>
          )}
          <p className={Styles.Description}>
            「請求」を選択すると金額はプラス値に、「返還」を選択するとマイナス値として扱われます。
          </p>
        </FormControl>
        <Flex gap="16px">
          <Grid width="50%">
            <FormControl chip="必須" label="金額" variant="Vertical">
              <Input
                isInvalid={!!errors.totalAmount}
                name="totalAmount"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const { value } = e.target;
                  if (Number(value) < 0) return;
                  if (value === '') {
                    setValue('totalAmount', value === '' ? '' : Number(value));
                    return;
                  }
                  setValue(
                    'totalAmount',
                    adjustTotalAmount(transactionType, Number(value))
                  );
                }}
                onWheel={(e): void => {
                  e.currentTarget.blur();
                }}
                placeholder="金額"
                type="number"
                value={totalAmount === '' ? '' : Math.abs(totalAmount)}
              />
            </FormControl>
          </Grid>
          <Grid width="50%">
            <FormControl chip="必須" label="税率" variant="Vertical">
              <InputSelect
                fullWidth
                isInvalid={errors.taxType != null}
                {...register('taxType')}
              >
                <option value="nontaxable">0%</option>
                <option value="standard">10%</option>
              </InputSelect>
              {errors.taxType != null && (
                <div className={Styles.InvalidFeedback}>
                  {errors.taxType.message}
                </div>
              )}
            </FormControl>
          </Grid>
        </Flex>
        {errors.totalAmount != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.totalAmount.message}
          </div>
        )}
        <FormControl chip="任意" label="備考" variant="Vertical">
          <InputText
            autoHeightLimit={8}
            isInvalid={!!errors.note}
            rows={2}
            {...register('note')}
            placeholder="備考）その他連絡事項など"
          />
        </FormControl>
        <Flex gap="8px">
          <Box width="50%">
            <div className={Styles.TotalAmountView}>
              ¥{toThousandSeparator(Number(totalAmount))}
            </div>
            <p className={Styles.Description}>返還分はマイナス表示となります</p>
          </Box>
          <Grid marginTop="4px" width="50%">
            <Button onClick={onClickSubmit} variant="secondary" width="fill">
              {type === 'add' ? '追加' : '更新'}
            </Button>
          </Grid>
        </Flex>
      </Flex>
    </Box>
  );
};

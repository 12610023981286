import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/deals/[id]/billings/edit/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (id: number): string => `/deals/${id}/billings/edit`;

const Billings: NextPage = () => (
  <>
    <NskHead title="オーナーへの請求明細" />
    <Component />
  </>
);

export default Billings;

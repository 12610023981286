import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import { NskResponse } from '~/models/Api';
import { DealSettlement, DealSettlementEdit } from '~/models/DealSettlement';
import { SettlementListRow } from './SettlementListRow';
import { DealBillingContext } from '~/models/DealBilling';
import { SettlementListFooterRow } from './SettlementListFooterRow';
import { SettlementListRestorationAmountRow } from './SettlementListRestorationAmountRow';

type Props = {
  constructionContext: DealBillingContext;
  handleMutateSettlements: () => Promise<void>;
  handleUpdate: (
    submitValue: DealSettlementEdit,
    billingId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (
    deleteSettlement: DealSettlement
  ) => Promise<NskResponse<void>>;
  handleUpdateSeq: (settlements: DealSettlement[]) => Promise<void>;
  target: 'owner' | 'rentee';
};

export const SettlementListRows: React.FC<Props> = ({
  constructionContext,
  handleMutateSettlements,
  handleUpdate,
  handleDestroy,
  handleUpdateSeq,
  target
}: Props) => {
  const { dealSettlements } = constructionContext;

  // 並び替えしたときにmutate前に画面に反映するためのstate
  const [showSettlements, setShowSettlements] = React.useState<
    DealSettlement[]
  >([]);

  React.useEffect(() => {
    setShowSettlements(dealSettlements);
  }, [dealSettlements]);

  const renderRow = (
    dealSettlement: DealSettlement,
    index: number
  ): ReactNode => (
    <SettlementListRow
      key={dealSettlement.id}
      dealSettlement={dealSettlement}
      handleDestroy={handleDestroy}
      handleMutateSettlements={handleMutateSettlements}
      handleUpdate={handleUpdate}
      index={index}
      target={target}
    />
  );
  const handleDragEnd = async (result: DropResult): Promise<void> => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const moveConstruction =
      dealSettlements.find(
        (e: DealSettlement) => String(e.id) === draggableId
      ) ?? null;
    const newConstruction: DealSettlement[] = [];
    const wkConstruction = [...dealSettlements];
    wkConstruction.splice(source.index, 1);
    if (moveConstruction !== null) {
      wkConstruction.splice(destination.index, 0, moveConstruction);
    }
    wkConstruction.forEach((e: DealSettlement, i: number) => {
      newConstruction.push({ ...e, seq: i + 1 });
    });
    setShowSettlements(newConstruction);
    await handleUpdateSeq(newConstruction);
    handleMutateSettlements();
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided): React.ReactElement<HTMLElement> => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            {showSettlements.map(renderRow)}
            {provided.placeholder}
            <SettlementListRestorationAmountRow
              constructionContext={constructionContext}
              target={target}
            />
            <SettlementListFooterRow
              constructionContext={constructionContext}
              target={target}
            />
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

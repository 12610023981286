import React from 'react';
import { toast } from 'react-toastify';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import clsx from 'clsx';
import Styles from './SettlementList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';
import { SettlementEditModal } from './SettlementEditModal';
import { DealSettlement, DealSettlementEdit } from '~/models/DealSettlement';

type Props = {
  dealSettlement: DealSettlement;
  handleMutateSettlements: () => Promise<void>;
  handleUpdate: (
    submitValue: DealSettlementEdit,
    billingId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (
    deleteSettlement: DealSettlement
  ) => Promise<NskResponse<void>>;
  index: number;
  target: 'owner' | 'rentee';
};

export const SettlementListRow: React.FC<Props> = ({
  dealSettlement,
  handleMutateSettlements,
  handleUpdate,
  handleDestroy,
  index,
  target
}: Props) => {
  const { ownerAmount, renteeAmount } = dealSettlement;
  const [showDestroyConfirm, setShowDestroyConfirm] =
    React.useState<boolean>(false);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const onClickEdit = (e: React.MouseEvent): void => {
    e.preventDefault();
    setShowEdit(true);
  };
  const onClickDelete = (e: React.MouseEvent): void => {
    e.preventDefault();
    setShowDestroyConfirm(true);
  };
  const handleDeleteEstimation = async (): Promise<void> => {
    const result = await handleDestroy(dealSettlement);
    if (isSuccess(result)) {
      await handleMutateSettlements();
      toast.success('選択項目を削除しました');
      setShowDestroyConfirm(false);
      return;
    }
    toast.error('エラーが発生しました');
  };
  return (
    <Draggable
      key={dealSettlement.id}
      draggableId={String(dealSettlement.id)}
      index={index}
    >
      {(provided: DraggableProvided): React.ReactElement<HTMLElement> => (
        <tr
          ref={provided.innerRef}
          className={clsx(Styles.ListRow, Styles.Enabled)}
          style={provided.draggableProps.style}
          {...provided.draggableProps}
        >
          <td className={Styles.OrderHandle} {...provided.dragHandleProps}>
            <img alt="drag handle" src="/assets/DragHandle.svg" />
          </td>
          <td className={Styles.Settlement}>
            {dealSettlement.name}
            <div className={Styles.SPInformation}>
              税率：
              {dealSettlement.taxRate}
            </div>
            {dealSettlement.note != null && dealSettlement.note !== '' && (
              <div className={Styles.Note}>備考）{dealSettlement.note}</div>
            )}
          </td>
          <td className={Styles.NonTaxAmount}>
            <span>
              ¥
              {toThousandSeparator(
                target === 'owner' ? ownerAmount : renteeAmount
              )}
            </span>
          </td>
          <td className={Styles.TaxRate}>
            <div>{dealSettlement.taxRate}</div>
          </td>
          <td className={Styles.TaxAmount}>
            <span>¥{toThousandSeparator(dealSettlement.amountWithTax)}</span>
          </td>
          <td className={Styles.Edit}>
            <a className={Styles.EditLink} href="#" onClick={onClickEdit}>
              編集
            </a>
            <a className={Styles.DeleteLink} href="#" onClick={onClickDelete}>
              削除
            </a>
            {showDestroyConfirm && (
              <ConfirmModal
                confirmType="delete"
                handleAccept={handleDeleteEstimation}
                handleCancel={(): void => setShowDestroyConfirm(false)}
                isOpen={showDestroyConfirm}
              >
                選択した精算項目を削除してよろしいですか？
              </ConfirmModal>
            )}
            {showEdit && (
              <SettlementEditModal
                dealSettlement={dealSettlement}
                handleClose={(): void => setShowEdit(false)}
                handleMutateSettlements={handleMutateSettlements}
                handleUpdate={handleUpdate}
              />
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};

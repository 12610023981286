import React from 'react';
import cx from 'clsx';
import Styles from './SettlementList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { DealBillingContext } from '~/models/DealBilling';

type Props = {
  constructionContext: DealBillingContext;
  target: 'owner' | 'rentee';
};

export const SettlementListFooterRow: React.FC<Props> = ({
  constructionContext,
  target
}: Props) => {
  const {
    settlementOwnerTotalAmount,
    settlementOwnerTotalAmountWithTax,
    settlementRenteeTotalAmount,
    settlementRenteeTotalAmountWithTax
  } = constructionContext;
  const isOwner = target === 'owner';
  const totalAmount = isOwner
    ? settlementOwnerTotalAmount
    : settlementRenteeTotalAmount;
  const totalAmountWithTax = isOwner
    ? settlementOwnerTotalAmountWithTax
    : settlementRenteeTotalAmountWithTax;
  return (
    <tr className={cx(Styles.ListRow, Styles.Footer)}>
      <td className={Styles.OrderHandle} />
      <td className={Styles.Settlement}>
        <div className={Styles.SumPoint}>精算項目合計</div>
      </td>
      <td className={Styles.NonTaxAmount}>
        ¥{toThousandSeparator(totalAmount)}
      </td>
      <td className={Styles.TaxRate} />
      <td className={Styles.TaxAmount}>
        ¥{toThousandSeparator(totalAmountWithTax)}
        <p className={Styles.Annotate}>返還分はマイナス表示となります</p>
      </td>
      <td className={Styles.Buttons} />
    </tr>
  );
};

import React from 'react';
import { Flex, InputRadio } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import {
  NotificationEdit,
  ONLY_ORIGIN_VENDOR_TRIGGER_TYPE,
  ONLY_SUB_VENDOR_TRIGGER_TYPE,
  RECEIVE_TYPE_LABELS
} from '~/models/Notification';
import Styles from '../NotificationModal.module.scss';

export const ReceiveTypeRadio: React.FC = () => {
  const { register, formState, watch } = useFormContext<NotificationEdit>();
  const { errors } = formState;
  const triggerType = watch('triggerType');
  const enableAll =
    triggerType &&
    !ONLY_ORIGIN_VENDOR_TRIGGER_TYPE.includes(triggerType) &&
    !ONLY_SUB_VENDOR_TRIGGER_TYPE.includes(triggerType);
  const enableOriginDealVendorCompany =
    triggerType && !ONLY_SUB_VENDOR_TRIGGER_TYPE.includes(triggerType);
  const enableSubDealVendorCompany =
    triggerType && !ONLY_ORIGIN_VENDOR_TRIGGER_TYPE.includes(triggerType);

  return (
    <>
      <Flex>
        {enableAll && (
          <InputRadio
            isInvalid={errors.receiveType != null}
            label={RECEIVE_TYPE_LABELS.all}
            value="all"
            {...register('receiveType')}
          />
        )}
        {enableOriginDealVendorCompany && (
          <InputRadio
            isInvalid={errors.receiveType != null}
            label={RECEIVE_TYPE_LABELS.origin_deal_vendor_company}
            value="origin_deal_vendor_company"
            {...register('receiveType')}
          />
        )}
        {enableSubDealVendorCompany && (
          <InputRadio
            isInvalid={errors.receiveType != null}
            label={RECEIVE_TYPE_LABELS.sub_deal_vendor_company}
            value="sub_deal_vendor_company"
            {...register('receiveType')}
          />
        )}
      </Flex>
      {errors.receiveType != null && (
        <div className={Styles.InvalidFeedback}>
          {errors.receiveType.message}
        </div>
      )}
    </>
  );
};

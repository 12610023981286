import React from 'react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalBody, ModalHeader, Modalv2 } from '@itandi/itandi-bb-ui';
import { isSuccess } from '~/utils/api/api_handler';
import { ConstructionForm } from '~/components/common/constructions/ConstructionForm';
import {
  buildDefaultConstruction,
  DealConstructionEdit,
  validationSchemaConstruction
} from '~/models/DealConstruction';
import { ConstructionItem } from '~/models/ConstructionItem';
import { NskResponse } from '~/models/Api';
import { ConstructionFormFooter } from '../ConstructionFormFooter';

type Props = {
  handleClose: () => void;
  constructionItems: ConstructionItem[];
  mutateConstructions: () => Promise<void>;
  handleCreate: (
    submitValue: DealConstructionEdit
  ) => Promise<NskResponse<void>>;
};

export const ConstructionAddModal: React.FC<Props> = ({
  handleClose,
  handleCreate,
  constructionItems,
  mutateConstructions
}: Props) => {
  const methods = useForm<DealConstructionEdit>({
    defaultValues: buildDefaultConstruction(),
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaConstruction)
  });
  const onClickSubmit = React.useCallback(
    (reInput: boolean) => {
      methods.handleSubmit(async () => {
        const response = await handleCreate(methods.getValues());
        if (isSuccess(response)) {
          await mutateConstructions();
          methods.reset();
          toast.success('追加しました');
          if (!reInput) {
            handleClose();
          }
          return;
        }
        toast.error('エラーが発生しました');
        methods.reset();
      })();
    },
    [handleClose, handleCreate, methods, mutateConstructions]
  );
  return (
    <Modalv2 handleClose={handleClose} isOpen>
      <ModalHeader title="見積り追加" />
      <ModalBody>
        <FormProvider {...methods}>
          <ConstructionForm constructionItems={constructionItems} />
          <ConstructionFormFooter
            onClickSubmit={onClickSubmit}
            type="add_modal"
          />
        </FormProvider>
      </ModalBody>
    </Modalv2>
  );
};

import React from 'react';
import { useDealEstimations } from '~/hooks/vendors/deals/useDealEstimations';
import { useDealInspectionReportImages } from '~/hooks/vendors/deals/useDealInspectionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH as INSPECTION_REPORT_PATH } from '~/pages/vendors/deals/[id]/inspection_reports';
import { PATH as COMPLETE_REPORT_PATH } from '~/pages/vendors/deals/[id]/completion_reports';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH as ESTIMATION_EDIT_PATH } from '~/pages/vendors/deals/[id]/estimations/edit';
import { ScheduleInputNavigation } from '~/components/common/inspection_schedule/ScheduleInputNavigation';
import { UploadFileNavigation } from '~/components/common/upload_files/UploadFileNavigation';
import { create as uploadMemorandumFile } from '~/utils/api/vendors/deals/DealMemorandomFile';
import { NskResponse } from '~/models/Api';
import { DealUploadFile } from '~/models/DealUploadFile';
import {
  destroy as destroyUploadFile,
  getFilePath as getVendorFilePath
} from '~/utils/api/vendors/deals/DealUploadedFile';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { update as updateDealInspection } from '~/utils/api/vendors/DealInspection';
import {
  IN_ESTIMATION,
  SEND_ESTIMATE,
  statusMovingValidation
} from '~/models/Status';
import { useSessions } from '~/hooks/vendors/useSessions';
import {
  getOriginDealVendor,
  isOriginVendor
} from '~/models/DealVendorCompany';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useDealCompletionReportImages } from '~/hooks/vendors/deals/useDealCompletionReportImages';
import { matchDealType } from '~/models/Deal';
import { navigationTitle } from '~/models/DealReportImage';
import { useVendorUsers } from '~/hooks/vendors/useVendorUsers';

export const InEstimation: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data: estimationContext } = useDealEstimations().index(dealId);
  const { data: vendorUsers } = useVendorUsers().index();
  const { data: inspectionImages } =
    useDealInspectionReportImages().index(dealId);
  const { data: completeImages } =
    useDealCompletionReportImages().index(dealId);
  const { data: session } = useSessions().index();
  const forwardStatusDisabled = React.useMemo(() => {
    if (
      deal === null ||
      estimationContext === null ||
      inspectionImages === null
    )
      return true;
    return statusMovingValidation[IN_ESTIMATION](
      deal,
      estimationContext,
      inspectionImages
    );
  }, [deal, estimationContext, inspectionImages]);
  if (
    deal === null ||
    estimationContext === null ||
    inspectionImages === null ||
    completeImages === null ||
    session === null
  ) {
    return null;
  }
  const handleUpdateDealInspection = async (
    inspectionDate: Date | null,
    userId: number | null
  ): Promise<NskResponse<void>> =>
    updateDealInspection(deal.id, inspectionDate, userId);
  const handleMutateDeal = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const handleUploadMemorandum = async (
    file: File
  ): Promise<NskResponse<void>> => uploadMemorandumFile(file, deal.id);
  const handleDestroyMemorandum = async (
    file: DealUploadFile
  ): Promise<NskResponse<void>> => destroyUploadFile(deal.id, file.id);
  const handleGetMemorandumFilePath = (file: DealUploadFile): string =>
    getVendorFilePath(deal.id, file);
  return (
    <div>
      <ScheduleInputNavigation
        deal={deal}
        handleMutate={handleMutateDeal}
        handleUpdate={handleUpdateDealInspection}
        // usePartnerかつoriginのときは指定しない
        vendorUsers={
          session?.vendorCompany.usePartner &&
          isOriginVendor(deal, session.vendorCompany.id)
            ? null
            : vendorUsers
        }
      />
      <ReportImageNavigation
        detailPageButtonLabel="画像登録画面"
        detailPagePath={INSPECTION_REPORT_PATH(deal.id)}
        optionalLabel={
          matchDealType(deal, 'repair') && !deal.conductFieldSurvey
        }
        reportImages={inspectionImages}
        title={navigationTitle(deal)}
      />
      {deal.dealType === 'repair' && (
        <ReportImageNavigation
          detailPageButtonLabel="画像登録画面"
          detailPagePath={COMPLETE_REPORT_PATH(deal.id)}
          optionalLabel
          reportImages={completeImages}
          title="完了報告画像を登録してください"
        />
      )}
      <ConstructionNavigation
        dealConstructionContext={estimationContext}
        detailPagePath={ESTIMATION_EDIT_PATH(deal.id)}
        title="見積りを入力してください"
      />
      {getOriginDealVendor(deal)?.showTenant && (
        <UploadFileNavigation
          deal={deal}
          files={deal.dealMemorandumFiles}
          fileType="賃借人同意書"
          handleDestroy={handleDestroyMemorandum}
          handleGetFilePath={handleGetMemorandumFilePath}
          handleMutate={handleMutateDeal}
          handleUpload={handleUploadMemorandum}
          title="賃借人同意書を登録してください"
        />
      )}
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <StatusHandleVendorButtons
          forwardDisabled={!forwardStatusDisabled}
          nextStatusId={SEND_ESTIMATE}
        />
      )}
    </div>
  );
};

import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { RiCalendarEventLine } from 'react-icons/ri';
import { Deal } from '~/models/Deal';
import { toDate } from '~/utils/DateUtil';
import { NskResponse } from '~/models/Api';
import { isSuccess } from '~/utils/api/api_handler';
import {
  ScheduleInputForm,
  ScheduleInputFormObject
} from '~/components/common/inspection_schedule/ScheduleInputForm';
import { getSelfDealVendor } from '~/models/DealVendorCompany';
import { VendorSession } from '~/models/VendorSession';
import { VendorUser } from '~/models/VendorUser';

type Props = {
  deal: Deal;
  isOpen: boolean;
  handleClose: () => void;
  handleUpdate: (
    inspectionDate: Date | null,
    userId: number | null
  ) => Promise<NskResponse<void>>;
  handleMutate: () => Promise<void>;
  session?: VendorSession | null;
  vendorUsers?: VendorUser[] | null;
};

export const ScheduleInputModal: React.FC<Props> = ({
  deal,
  isOpen,
  handleClose,
  vendorUsers = null,
  session = null,
  handleUpdate,
  handleMutate
}: Props) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const selfVendorUserId = React.useMemo(() => {
    if (deal === null || session === null) {
      return null;
    }
    const selfVendor = getSelfDealVendor(deal, session.vendorCompany.id);
    if (selfVendor === null || selfVendor.user === null) {
      return null;
    }
    return selfVendor.user.id;
  }, [deal, session]);
  const [values, setValues] = React.useState<ScheduleInputFormObject>({
    inspectionDate: toDate(deal.inspectionDate),
    userId: selfVendorUserId
  });
  const handleChange = (newValues: ScheduleInputFormObject): void => {
    setValues(newValues);
  };
  const onSubmitSchedule = async (): Promise<void> => {
    setSubmitting(true);
    const result = await handleUpdate(values.inspectionDate, values.userId);
    if (isSuccess(result)) {
      await handleMutate();
      handleClose();
      toast.success('登録しました');
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    toast.error('エラーが発生しました');
  };
  const disabled = vendorUsers !== null && values.userId === null;
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader icon={RiCalendarEventLine} title="立会日程登録" />
      <ModalBody>
        <ScheduleInputForm
          deal={deal}
          handleChange={handleChange}
          values={values}
          vendorUsers={vendorUsers}
        />
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary" width="fill">
            キャンセル
          </Button>
        }
        rightArea={
          <Button
            disabled={submitting || disabled}
            onClick={onSubmitSchedule}
            variant="primary"
            width="fill"
          >
            確定
          </Button>
        }
      />
    </Modalv2>
  );
};

import { NskResponse } from '~/models/Api';
import { putRequest } from '../api_handler';

const paths = {
  update: '/vendors/deal_inspections/:id'
};

export const update = async (
  dealId: number,
  inspectionDate: Date | null,
  userId: number | null
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':id', String(dealId)), {
    inspectionDate,
    userId
  });

import { number, object, string } from 'yup';
import { BillingType, DealBilling } from './DealBilling';
import { DealConstructionBase } from './DealConstruction';

export type DealSettlement = Pick<
  DealConstructionBase,
  | 'id'
  | 'seq'
  | 'settlementItem'
  | 'otherName'
  | 'totalAmount'
  | 'ownerAmount'
  | 'renteeAmount'
  | 'companyAmount'
  | 'note'
  | 'enabled'
> & {
  name: string;
  taxRate: string;
  amountWithTax: number;
  type: 'DealBilling';
  billingType: BillingType;
};

export type DealSettlementEdit = Pick<
  DealBilling,
  | 'settlementItem'
  | 'otherName'
  | 'taxType'
  | 'unit'
  | 'unitAmount'
  | 'transactionType'
  | 'ownerChargePer'
  | 'renteeChargePer'
  | 'companyChargePer'
  | 'note'
  | 'billingType'
> & {
  totalAmount: number | '';
};

export const validationSchemaSettlement = object().shape({
  settlementItem: object().nullable().required('精算項目を設定してください'),
  taxType: string().nullable().required('選択してください'),
  transactionType: string().nullable().required('選択してください'),
  totalAmount: number()
    .transform((value: string) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('入力してください')
});

export const buildDefaultSettlement = (
  billingType: 'owner' | 'rentee'
): DealSettlementEdit => ({
  settlementItem: null,
  otherName: '',
  unit: 1,
  unitAmount: 0,
  totalAmount: 0,
  taxType: 'nontaxable',
  transactionType: billingType === 'rentee' ? 'refund' : 'billing',
  ownerChargePer: billingType === 'owner' ? 100 : 0,
  renteeChargePer: billingType === 'rentee' ? 100 : 0,
  companyChargePer: 0,
  note: '',
  billingType
});

import { boolean, object, string } from 'yup';
import { Deal } from './Deal';

// TODO 必須項目が埋まったら型定義を変える
export type VendorCompany = {
  id: number;
  name: string;
  loginEmail: string;
  ceoLastName: string | null;
  ceoFirstName: string | null;
  tel: string | null;
  fax: string | null;
  email: string;
  postalCode: string | null;
  address: string | null;
  teamName: string;
  staffName: string;
  allowPartiallyFinished: boolean;
  enabledDealReceiptFileTemplate?: boolean | null;
  /** vendor_partnersを持っているか */
  usePartner: boolean;
  useLineNotification?: boolean;
};

export type VendorToCompanySetting = {
  defaultCautionaryNoteToCompany: string | null;
};

type OptionalProp =
  | 'id'
  | 'ceoLastName'
  | 'ceoFirstName'
  | 'email'
  | 'postalCode'
  | 'address'
  | 'allowPartiallyFinished'
  | 'enabledDealReceiptFileTemplate'
  | 'usePartner';

export type VendorCompanyUpdateSelfParams = Omit<
  VendorCompany,
  OptionalProp
> & {
  ceoLastName: string;
  ceoFirstName: string;
  postalCode: string;
  address: string;
  defaultCautionaryNoteToCompany: string;
};

export type VendorCompanyCreateParams = Omit<
  VendorCompanyUpdateSelfParams,
  'tel' | 'fax' | 'teamName' | 'staffName' | 'defaultCautionaryNoteToCompany'
> & {
  loginEmail: string;
  password: string;
};

export type VendorCompanyUpdatePasswordParam = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type InviteVendorCompany = {
  name: string;
  loginEmail: string;
  password: string;
  ceoLastName: string;
  ceoFirstName: string;
  confirmPassword: string;
  tel: string;
  fax: string;
  postalCode: string;
  address: string;
  userName: string;
  userEmail: string;
  userTel: string;
  checkTerm: boolean;
};

export const VENDOR_COMPANY_ITANDI_ID = 1;

export const isItandiVendor = (deal: Deal): boolean =>
  deal?.vendorCompany?.id === VENDOR_COMPANY_ITANDI_ID;

export const vendorCompanyLinkLoginSchema = object().shape({
  email: string()
    .required('入力してください')
    .email('メールアドレスの形式を確認してください'),
  password: string().required('入力してください')
});

export const vendorCompanyCreateValidationSchema = object().shape({
  name: string().required('入力してください'),
  loginEmail: string()
    .required('入力してください')
    .email('メールアドレスの形式を確認してください'),
  password: string().required('入力してください'),
  ceoLastName: string().required('入力してください'),
  ceoFirstName: string().required('入力してください'),
  postalCode: string().required('入力してください'),
  address: string().required('入力してください')
});

export const vendorCompanyEditValidationSchema = object().shape({
  name: string().required('入力してください'),
  loginEmail: string()
    .required('入力してください')
    .email('メールアドレスの形式を確認してください'),
  ceoLastName: string().required('入力してください'),
  ceoFirstName: string().required('入力してください'),
  postalCode: string().required('入力してください'),
  address: string().required('入力してください'),
  defaultCautionaryNoteToCompany: string().max(
    10000,
    '10000文字以内で設定してください'
  )
});

export const commonNewPasswordValidationSchema = object().shape({
  newPassword: string()
    .required('入力してください')
    .min(8, '8文字以上で設定してください')
    .max(255, '255文字以内で設定してください'),
  confirmPassword: string()
    .test(
      'confirmPassword',
      'パスワードが一致しません',
      function testConfirmPassword(value: string | undefined) {
        const params: VendorCompanyUpdatePasswordParam = this.parent;
        if (params.newPassword === '' || params.newPassword == null) {
          return true;
        }
        return params.newPassword === value;
      }
    )
    .max(255, '255文字以内で設定してください')
});
export const requireOldPasswordValidationSchema =
  commonNewPasswordValidationSchema.concat(
    object({
      oldPassword: string().required('入力してください')
    })
  );

export const validationSchemaInviteVendorCompany = object().shape({
  name: string().required('入力してください'),
  loginEmail: string()
    .required('入力してください')
    .email('メールアドレスの形式を確認してください'),
  password: string()
    .required('入力してください')
    .min(8, '8文字以上で設定してください'),
  confirmPassword: string()
    .test(
      'passwordConfirm',
      'パスワードが一致しません',
      function testConfirmPassword(value: string | undefined) {
        const { password } = this.parent;
        if (password === '' || password == null) {
          return true;
        }
        return value === password;
      }
    )
    .max(255, '255文字以内で設定してください'),
  ceoLastName: string().required('入力してください'),
  ceoFirstName: string().required('入力してください'),
  postalCode: string()
    .required('入力してください')
    .test(
      'len',
      '7桁で入力してください',
      (val: string | undefined) => !val || val.length === 7
    ),
  address: string().required('入力してください'),
  tel: string().required('入力してください'),
  userName: string().required('入力してください'),
  userEmail: string()
    .email('メールアドレスの形式を確認してください')
    .required('入力してください'),
  userTel: string().required('入力してください'),
  checkTerm: boolean().isTrue('チェックしてください')
});

import React from 'react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalBody, ModalHeader, Modalv2 } from '@itandi/itandi-bb-ui';
import { ConstructionForm } from '~/components/common/constructions/ConstructionForm';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';
import { ConstructionItem } from '~/models/ConstructionItem';
import {
  buildDefaultConstruction,
  DealConstruction,
  DealConstructionEdit,
  validationSchemaConstruction
} from '~/models/DealConstruction';
import { ConstructionFormFooter } from '../ConstructionFormFooter';

type Props = {
  dealConstruction: DealConstruction;
  constructionItems: ConstructionItem[];
  handleMutateConstructions: () => Promise<void>;
  handleClose: () => void;
  handleUpdate: (
    submitValue: DealConstructionEdit,
    estimationId: number
  ) => Promise<NskResponse<void>>;
};

export const ConstructionEditModal: React.FC<Props> = ({
  dealConstruction,
  handleMutateConstructions,
  constructionItems,
  handleClose,
  handleUpdate
}: Props) => {
  const methods = useForm<DealConstructionEdit>({
    defaultValues: dealConstruction ?? buildDefaultConstruction(),
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaConstruction)
  });
  const onClickSubmit = React.useCallback(() => {
    methods.handleSubmit(async () => {
      const response = await handleUpdate(
        methods.getValues(),
        dealConstruction.id
      );
      if (isSuccess(response)) {
        await handleMutateConstructions();
        methods.reset();
        handleClose();
        toast.success('見積りを更新しました');
        return;
      }
      toast.error('エラーが発生しました');
      handleClose();
      methods.reset();
    })();
  }, [
    dealConstruction.id,
    handleClose,
    handleMutateConstructions,
    handleUpdate,
    methods
  ]);
  return (
    <Modalv2 handleClose={handleClose} isOpen>
      <ModalHeader title="見積りの修正" />
      <ModalBody>
        <FormProvider {...methods}>
          <ConstructionForm constructionItems={constructionItems} />
          <ConstructionFormFooter onClickSubmit={onClickSubmit} type="edit" />
        </FormProvider>
      </ModalBody>
    </Modalv2>
  );
};

import { colors } from '@itandi/itandi-bb-ui';
import { Deal } from '~/models/Deal';
import {
  inConstructionDisabled,
  inEstimationDisabled
} from '~/utils/ForwardStatusDisabledVendorUtil';
import {
  DealCompletionReportImage,
  DealInspectionReportImage
} from './DealReportImage';
import { DealEstimationContext } from './DealEstimate';
import { ItandiAccount } from './ItandiAccount';
import { canMovingCompeteStatus, canMovingStatus } from './Staff';

export type Status = {
  id: StatusId;
  useType: 'with_vendor' | 'own' | 'common';
  name: string;
  statusBarDisplay: boolean;
};

// with vendor statuses
/** 業者選択中 */
export const NOW_ACCEPTING = 10;
/** 見積作成中 */
export const IN_ESTIMATION = 15;
/** 見積確認中 */
export const SEND_ESTIMATE = 40;
/** 工事完了待ち */
export const IN_CONSTRUCTION = 45;
/** 検収中 */
export const AWAITING_ACCEPTANCE = 90;
/** 途中完了 */
export const PARTIALLY_FINISHED = 95;

/** 完了 */
export const FINISHED = 100;

// client self statuses
/** [管理会社管理ステータス] 見積作成中  */
export const OWN_IN_ESTIMATION = 101;
/** [管理会社管理ステータス] 見積確認中  */
export const OWN_SEND_ESTIMATE = 105;
/** [管理会社管理ステータス] 工事完了待ち */
export const OWN_IN_CONSTRUCTION = 111;
/** [管理会社管理ステータス] 検収中 */
export const OWN_AWAITING_ACCEPTANCE = 190;
/** [管理会社管理ステータス] 完了 */
export const OWN_FINISHED = 200;

const OWN_STATUSES = [
  OWN_IN_ESTIMATION,
  OWN_SEND_ESTIMATE,
  OWN_IN_CONSTRUCTION,
  OWN_AWAITING_ACCEPTANCE,
  OWN_FINISHED
];

/** 取り下げ */
export const CANCELED = 901;

const ACTIVE_STATUSES = [
  NOW_ACCEPTING,
  IN_ESTIMATION,
  SEND_ESTIMATE,
  IN_CONSTRUCTION,
  AWAITING_ACCEPTANCE,
  OWN_IN_ESTIMATION,
  OWN_SEND_ESTIMATE,
  OWN_IN_CONSTRUCTION,
  OWN_AWAITING_ACCEPTANCE
];

export type StatusId =
  | typeof NOW_ACCEPTING
  | typeof IN_ESTIMATION
  | typeof SEND_ESTIMATE
  | typeof IN_CONSTRUCTION
  | typeof AWAITING_ACCEPTANCE
  | typeof PARTIALLY_FINISHED
  | typeof FINISHED
  | typeof OWN_IN_ESTIMATION
  | typeof OWN_SEND_ESTIMATE
  | typeof OWN_IN_CONSTRUCTION
  | typeof OWN_AWAITING_ACCEPTANCE
  | typeof OWN_FINISHED
  | typeof CANCELED;

export const isCancelApplying = (deal: Deal): boolean =>
  deal.dealCancel != null && deal.dealCancel.temporary;

export const isOwnStatus = (statusId: number): boolean =>
  OWN_STATUSES.includes(statusId);

export const isCanceled = (statusId: number): boolean =>
  [CANCELED].includes(statusId);

export const isCompleted = (statusId: number): boolean =>
  [PARTIALLY_FINISHED, FINISHED, OWN_FINISHED].includes(statusId);

export const getOwnStatuses = (statuses: Status[]): Status[] =>
  statuses.filter((s: Status) => ['own', 'common'].includes(s.useType));

export const getWithVendorStatuses = (statuses: Status[]): Status[] =>
  statuses.filter((s: Status) => ['with_vendor', 'common'].includes(s.useType));

export const statusLabel = (status: Status): string =>
  `${status.useType === 'own' ? '[非共有]' : ''}${status.name}`;

type MovingStatusId =
  | typeof NOW_ACCEPTING
  | typeof IN_ESTIMATION
  | typeof SEND_ESTIMATE
  | typeof IN_CONSTRUCTION
  | typeof AWAITING_ACCEPTANCE;

type OwnMovingStatusId =
  | typeof OWN_IN_ESTIMATION
  | typeof OWN_SEND_ESTIMATE
  | typeof OWN_IN_CONSTRUCTION
  | typeof OWN_AWAITING_ACCEPTANCE;

type statusMovingDisabledArgs = {
  [key in MovingStatusId]:
    | ((account: ItandiAccount) => boolean)
    | ((
        deal: Deal,
        estimationContext: DealEstimationContext,
        inspectionImages: DealInspectionReportImage[]
      ) => boolean)
    | ((
        inspectionImages: DealInspectionReportImage[],
        completeImages: DealCompletionReportImage[],
        completionReportImageRequired: boolean
      ) => boolean);
};
export const statusMovingValidation = {
  10: (account: ItandiAccount) => canMovingStatus(account),
  15: (
    deal: Deal,
    estimationContext: DealEstimationContext,
    inspectionImages: DealInspectionReportImage[]
  ) => !inEstimationDisabled(deal, estimationContext, inspectionImages),
  40: () => true,
  45: (
    inspectionImages: DealInspectionReportImage[],
    completeImages: DealCompletionReportImage[],
    completionReportImageRequired: boolean
  ) =>
    !inConstructionDisabled(
      inspectionImages,
      completeImages,
      completionReportImageRequired
    ),
  90: (account: ItandiAccount) => canMovingCompeteStatus(account)
} as const satisfies statusMovingDisabledArgs;

export const ownStatusMovingValidation = {
  101: (account: ItandiAccount) => canMovingStatus(account),
  105: (account: ItandiAccount) => canMovingStatus(account),
  111: (account: ItandiAccount) => canMovingStatus(account),
  190: (account: ItandiAccount) => canMovingCompeteStatus(account)
} as const satisfies {
  [key in OwnMovingStatusId]: (account: ItandiAccount) => boolean;
};

export const statusColor: { [key in StatusId]: string } = {
  [NOW_ACCEPTING]: colors['expressive-aqua'],
  [IN_ESTIMATION]: colors['expressive-blue'],
  [SEND_ESTIMATE]: colors['expressive-aqua-dark'],
  [IN_CONSTRUCTION]: colors['expressive-yellow'],
  [AWAITING_ACCEPTANCE]: colors['expressive-yellow-dark'],
  [PARTIALLY_FINISHED]: colors['semantic-positive500'],
  [FINISHED]: colors['semantic-positive'],
  [OWN_IN_ESTIMATION]: colors['expressive-blue'],
  [OWN_SEND_ESTIMATE]: colors['expressive-aqua-dark'],
  [OWN_IN_CONSTRUCTION]: colors['expressive-yellow'],
  [OWN_AWAITING_ACCEPTANCE]: colors['expressive-yellow-dark'],
  [OWN_FINISHED]: colors['semantic-positive'],
  [CANCELED]: colors['semantic-negative']
} as const satisfies { [key in StatusId]: string };

export const convertToComboBoxOption = (
  status: Status
): { label: string; value: string; iconColor: string } => ({
  label: statusLabel(status),
  iconColor: statusColor[status.id],
  value: String(status.id)
});

export const defaultSelectedOption = (
  statuses: Status[]
): Array<{ label: string; value: string }> =>
  statuses
    .filter((s) => ACTIVE_STATUSES.includes(s.id))
    .map((s) => convertToComboBoxOption(s));

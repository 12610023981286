import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { SettlementItem } from '~/models/SettlementItem';

const paths = {
  index: '/settlement_items'
};
type TIndex = () => SwrReturnType<SettlementItem[]>;
type ReturnType = {
  index: TIndex;
};

export const useSettlementItems = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<SettlementItem[]>(paths.index);
    return {
      data: data ?? [],
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { ConstructionForm } from '~/components/common/constructions/ConstructionForm';
import {
  buildDefaultConstruction,
  DealConstructionEdit,
  validationSchemaConstruction
} from '~/models/DealConstruction';
import { NskResponse } from '~/models/Api';
import { ConstructionFormFooter } from '../ConstructionFormFooter';
import { isSuccess } from '~/utils/api/api_handler';
import { ConstructionItem } from '~/models/ConstructionItem';

type Props = {
  constructionItems: ConstructionItem[];
  handleCreate: (
    submitValue: DealConstructionEdit
  ) => Promise<NskResponse<void>>;
  mutateConstructions: () => Promise<void>;
};
export const ConstructionAddForm: React.FC<Props> = ({
  constructionItems,
  handleCreate,
  mutateConstructions
}: Props) => {
  const methods = useForm<DealConstructionEdit>({
    defaultValues: buildDefaultConstruction(),
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaConstruction)
  });
  const onClickSubmit = React.useCallback(() => {
    if (handleCreate === null) return;
    methods.handleSubmit(async () => {
      const response = await handleCreate(methods.getValues());
      if (isSuccess(response)) {
        await mutateConstructions();
        methods.reset();
        toast.success('追加しました');
        return;
      }
      toast.error('エラーが発生しました');
      methods.reset();
    })();
  }, [handleCreate, methods, mutateConstructions]);
  return (
    <FormProvider {...methods}>
      <ConstructionForm constructionItems={constructionItems} />
      <ConstructionFormFooter onClickSubmit={onClickSubmit} type="add" />
    </FormProvider>
  );
};

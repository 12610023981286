import React from 'react';
import { BigNumber } from 'bignumber.js';
import {
  Box,
  Flex,
  FormControl,
  Grid,
  Input,
  InputSelect,
  InputText
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './ConstructionForm.module.scss';
import { ConstructionItem } from '~/models/ConstructionItem';
import { DealConstructionEdit } from '~/models/DealConstruction';
import { OTHER_CONSTRUCTION_ITEM_ID } from '~/constants/DealConstructionConst';

type Props = {
  constructionItems: ConstructionItem[];
};

export const ConstructionForm: React.FC<Props> = ({
  constructionItems
}: Props) => {
  const { register, formState, setValue, watch } =
    useFormContext<DealConstructionEdit>();
  const { errors } = formState;
  const constructionItem = watch('constructionItem');
  const companyChargePer = watch('companyChargePer');
  const unit = watch('unit');
  const unitAmount = watch('unitAmount');
  const reCalcTotalAmount = React.useCallback(
    (quantity: number, price: number): void => {
      if (Number.isNaN(quantity) || Number.isNaN(price)) {
        return;
      }
      const newTotalAmount: number = BigNumber(quantity)
        .multipliedBy(BigNumber(price))
        .toNumber();
      setValue('totalAmount', newTotalAmount);
    },
    [setValue]
  );
  const onChangeItem = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = e.target;
      const findItem =
        constructionItems.find(
          (item: ConstructionItem) => item.id === Number(value)
        ) ?? null;
      setValue('constructionItem', findItem);
      setValue('unitAmount', findItem?.unitAmount ?? 0);
      if (findItem !== null) {
        reCalcTotalAmount(Number(unit), findItem.unitAmount);
      }
    },
    [constructionItems, reCalcTotalAmount, setValue, unit]
  );
  const handleChangeAmount = React.useCallback(
    (key: 'unit' | 'unitAmount') =>
      (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        setValue(key, value === '' ? '' : Number(value));
        if (key === 'unitAmount') {
          reCalcTotalAmount(Number(unit), Number(value));
        } else {
          reCalcTotalAmount(Number(value), Number(unitAmount));
        }
      },
    [setValue, reCalcTotalAmount, unitAmount, unit]
  );
  const handleChangeRenteeChargePer = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) return;
      setValue('renteeChargePer', value === '' ? '' : Number(value));
      if (Number(value) > 100) return;
      const ownerChargePer = 100 - Number(value) - Number(companyChargePer);
      setValue('ownerChargePer', ownerChargePer);
    },
    [companyChargePer, setValue]
  );

  return (
    <div>
      <Box margin="8px">
        <Flex direction="column" gap="16px">
          <FormControl chip="必須" label="施工項目" variant="Vertical">
            <InputSelect
              fullWidth
              isInvalid={!!errors.constructionItem}
              onChange={onChangeItem}
              value={constructionItem?.id ?? -1}
            >
              <option key={-1} value={-1}>
                施工項目を選択
              </option>
              {constructionItems.map(
                (item: ConstructionItem): React.ReactNode => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              )}
            </InputSelect>
            {errors.constructionItem != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.constructionItem.message}
              </div>
            )}
          </FormControl>
          {constructionItem?.id === OTHER_CONSTRUCTION_ITEM_ID && (
            <FormControl chip="任意" label="施工項目名" variant="Vertical">
              <Input
                isInvalid={!!errors.otherName}
                placeholder="例）諸経費"
                {...register('otherName')}
              />
            </FormControl>
          )}
          <FormControl chip="任意" label="施工箇所" variant="Vertical">
            <Input
              isInvalid={!!errors.point}
              placeholder="例）リビング"
              {...register('point')}
            />
          </FormControl>
          <Flex gap="8px">
            <Grid width="50%">
              <FormControl chip="必須" label="単価" variant="Vertical">
                <Input
                  isInvalid={!!errors.unitAmount}
                  onWheel={(e): void => {
                    e.currentTarget.blur();
                  }}
                  placeholder="単価"
                  type="number"
                  {...register('unitAmount')}
                  onChange={handleChangeAmount('unitAmount')}
                />
              </FormControl>
            </Grid>
            <Grid width="50%">
              <FormControl chip="必須" label="数量" variant="Vertical">
                <Input
                  isInvalid={!!errors.unit}
                  onWheel={(e): void => {
                    e.currentTarget.blur();
                  }}
                  placeholder="数量"
                  type="number"
                  {...register('unit')}
                  onChange={handleChangeAmount('unit')}
                />
              </FormControl>
            </Grid>
          </Flex>
          <Flex gap="8px">
            <Grid width="50%">
              {errors.unitAmount != null && (
                <div className={Styles.InvalidFeedback}>
                  {errors.unitAmount.message}
                </div>
              )}
            </Grid>
            <Grid width="50%">
              {errors.unit != null && (
                <div className={Styles.InvalidFeedback}>
                  {errors.unit.message}
                </div>
              )}
            </Grid>
          </Flex>
          <Flex gap="8px">
            <Grid width="33%">
              <FormControl label="借主負担%" variant="Vertical">
                <Input
                  isInvalid={!!errors.renteeChargePer}
                  placeholder="借主負担％"
                  {...register('renteeChargePer')}
                  onChange={handleChangeRenteeChargePer}
                />
              </FormControl>
            </Grid>
            <Grid width="33%">
              <FormControl label="貸主負担%" variant="Vertical">
                <Input
                  isInvalid={errors.ownerChargePer != null}
                  placeholder="貸主負担％"
                  {...register('ownerChargePer')}
                />
              </FormControl>
            </Grid>
            <Grid width="33%">
              <FormControl label="管理負担%" variant="Vertical">
                <Input
                  isInvalid={errors.companyChargePer != null}
                  placeholder="管理負担％"
                  {...register('companyChargePer')}
                />
              </FormControl>
            </Grid>
          </Flex>
          {(errors.renteeChargePer != null ||
            errors.ownerChargePer != null ||
            errors.companyChargePer != null) && (
            <div className={Styles.InvalidFeedback}>
              {errors.renteeChargePer?.message ??
                errors.ownerChargePer?.message ??
                errors.companyChargePer?.message}
            </div>
          )}
          <FormControl chip="任意" label="備考" variant="Vertical">
            <InputText
              autoHeightLimit={8}
              isInvalid={!!errors.note}
              placeholder="備考）その他連絡事項など"
              rows={2}
              {...register('note')}
            />
          </FormControl>
        </Flex>
      </Box>
    </div>
  );
};

import { BillingType } from '~/models/DealBilling';

const paths = {
  index: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:deal_id/deal_settlement_files?billing_type=:billing_type`
};

export const getDownloadPath = (
  dealId: number,
  billingType: BillingType
): string =>
  paths.index
    .replace(':deal_id', String(dealId))
    .replace(':billing_type', billingType);

import React from 'react';
import cx from 'clsx';
import Styles from './SettlementList.module.scss';
import { NskResponse } from '~/models/Api';
import { DealSettlement, DealSettlementEdit } from '~/models/DealSettlement';
import { SettlementListRows } from './SettlementListRows';
import { DealBillingContext } from '~/models/DealBilling';

type Props = {
  target: 'owner' | 'rentee';
  constructionContext: DealBillingContext;
  handleMutateSettlements: () => Promise<void>;
  handleUpdate: (
    submitValue: DealSettlementEdit,
    billingId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (
    deleteSettlement: DealSettlement
  ) => Promise<NskResponse<void>>;
  handleUpdateSeq: (settlements: DealSettlement[]) => Promise<void>;
};
export const SettlementList: React.FC<Props> = ({
  target,
  constructionContext,
  handleMutateSettlements,
  handleUpdate,
  handleDestroy,
  handleUpdateSeq
}: Props) => (
  <div className={Styles.Table}>
    <table className={Styles.List}>
      <thead>
        <tr className={cx(Styles.ListRow, Styles.Header)}>
          <th className={Styles.OrderHandle}>並替</th>
          <th className={Styles.Settlement}>精算項目</th>
          <th className={Styles.NonTaxAmount}>金額（税抜）</th>
          <th className={Styles.TaxRate}>税率</th>
          <th className={Styles.TaxAmount}>金額（税込）</th>
          <th className={Styles.Edit} />
        </tr>
      </thead>
      <SettlementListRows
        constructionContext={constructionContext}
        handleDestroy={handleDestroy}
        handleMutateSettlements={handleMutateSettlements}
        handleUpdate={handleUpdate}
        handleUpdateSeq={handleUpdateSeq}
        target={target}
      />
    </table>
  </div>
);

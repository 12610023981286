import React from 'react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { isSuccess } from '~/utils/api/api_handler';
import {
  buildDefaultSettlement,
  DealSettlementEdit,
  validationSchemaSettlement
} from '~/models/DealSettlement';
import { NskResponse } from '~/models/Api';
import { useSettlementItems } from '~/hooks/useSettlementItems';
import { SettlementForm } from '../SettlementForm';

type Props = {
  billingType: 'owner' | 'rentee';
  handleCreate: (submitValue: DealSettlementEdit) => Promise<NskResponse<void>>;
  mutateConstructions: () => Promise<void>;
};
export const SettlementAddForm: React.FC<Props> = ({
  billingType,
  handleCreate,
  mutateConstructions
}: Props) => {
  const { data: settlementItems } = useSettlementItems().index();
  const methods = useForm<DealSettlementEdit>({
    defaultValues: buildDefaultSettlement(billingType),
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaSettlement)
  });
  const onClickSubmit = React.useCallback(() => {
    methods.handleSubmit(async () => {
      const response = await handleCreate(methods.getValues());
      if (isSuccess(response)) {
        await mutateConstructions();
        methods.reset();
        toast.success('追加しました');
        return;
      }
      toast.error('エラーが発生しました');
      methods.reset();
    })();
  }, [handleCreate, methods, mutateConstructions]);
  if (settlementItems === null) return null;
  return (
    <FormProvider {...methods}>
      <SettlementForm
        onClickSubmit={onClickSubmit}
        settlementItems={settlementItems}
        type="add"
      />
    </FormProvider>
  );
};

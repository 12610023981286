import React from 'react';
import { Button, Flex, RiAddBoxLine } from '@itandi/itandi-bb-ui';
import Styles from './ConstructionTop.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import {
  DealConstructionContext,
  isDealBillingContext
} from '~/models/DealConstruction';
import { MEDIA_QUERY, useMediaQuery } from '~/hooks/useMediaQuery';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { getDownloadPath } from '~/utils/api/deals/DealSettlementFile';

type Props = {
  readonly: boolean;
  constructionContext: DealConstructionContext;
  handleClickPreview: () => void;
  handleClickShowAddModal?: (() => void) | null;
  handleClickChangeAmount?: (() => void) | null;
  billingTarget?: 'owner' | 'rentee' | null;
  useSK?: boolean;
};

export const ConstructionTop: React.FC<Props> = ({
  readonly,
  constructionContext,
  handleClickPreview,
  handleClickShowAddModal = null,
  handleClickChangeAmount = null,
  billingTarget = null,
  useSK = false
}: Props) => {
  const { match: isSp, isLoaded: windowLoaded } = useMediaQuery(MEDIA_QUERY.sp);
  const { totalAmount, totalAmountWithTax } = constructionContext;
  const id = useIdQueryHandler();
  const showSettlementInformation =
    isDealBillingContext(constructionContext) &&
    constructionContext.dealSettlements.length > 0;
  const handleClickSettlementPreview = (): void => {
    if (id === null || billingTarget === null) return;
    const path = getDownloadPath(id, billingTarget);
    window.open(path);
  };
  if (!windowLoaded) return null;
  return (
    <div className={Styles.EstimateTop}>
      <Flex direction="column" gap="8px">
        {showSettlementInformation && (
          <div className={Styles.Amounts}>
            <span className={Styles.TotalAmount}>
              精算項目総額：¥
              {toThousandSeparator(
                billingTarget === 'owner'
                  ? constructionContext.settlementOwnerTotalAmountWithTax
                  : constructionContext.settlementRenteeTotalAmountWithTax
              )}
            </span>
            <span className={Styles.TotalAmountWithoutTax}>
              税抜：¥
              {toThousandSeparator(
                billingTarget === 'owner'
                  ? constructionContext.settlementOwnerTotalAmount
                  : constructionContext.settlementRenteeTotalAmount
              )}
            </span>
          </div>
        )}
        <div className={Styles.Amounts}>
          <span className={Styles.TotalAmount}>
            {billingTarget === null ? '総額' : '施工項目総額'}：¥
            {toThousandSeparator(totalAmountWithTax)}
          </span>
          <span className={Styles.TotalAmountWithoutTax}>
            税抜：¥{toThousandSeparator(totalAmount)}
          </span>
        </div>
      </Flex>
      <Flex
        gap="8px"
        marginLeft="auto"
        paddingX="8px"
        width={isSp ? '100%' : 'auto'}
      >
        {handleClickChangeAmount !== null && (
          <Button
            onClick={handleClickChangeAmount}
            variant="tertiary"
            width={isSp ? 'fill' : 'default'}
          >
            施工単価を一括で変更する
          </Button>
        )}
        {!useSK && billingTarget !== null && (
          <Button
            disabled={!showSettlementInformation}
            onClick={handleClickSettlementPreview}
            variant="secondary"
            width={isSp ? 'fill' : 'default'}
          >
            精算書ダウンロード
          </Button>
        )}
        <Button
          disabled={constructionContext.dealConstructions.length === 0}
          onClick={handleClickPreview}
          variant="secondary"
          width={isSp ? 'fill' : 'default'}
        >
          {billingTarget === null ? '見積書ダウンロード' : '請求書ダウンロード'}
        </Button>
        {handleClickShowAddModal !== null && !readonly && (
          <div className={Styles.AddButton}>
            <Button
              onClick={handleClickShowAddModal}
              startIcon={<RiAddBoxLine />}
              variant="secondary"
              width={isSp ? 'fill' : 'default'}
            >
              項目追加
            </Button>
          </div>
        )}
      </Flex>
    </div>
  );
};

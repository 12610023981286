import React from 'react';
import { RiCalendarLine } from 'react-icons/ri';
import Styles from './ScheduleInputNavigation.module.scss';
import { Button } from '~/components/common/parts/button/Button';
import { formatDate } from '~/utils/DateUtil';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ScheduleInputModal } from '~/components/common/inspection_schedule/ScheduleInputModal';
import { NskResponse } from '~/models/Api';
import { Deal } from '~/models/Deal';
import { VendorUser } from '~/models/VendorUser';

type Props = {
  deal: Deal;
  handleUpdate: (
    inspectionDate: Date | null,
    userId: number | null
  ) => Promise<NskResponse<void>>;
  handleMutate: () => Promise<void>;
  vendorUsers?: VendorUser[] | null;
};

export const ScheduleInputNavigation: React.FC<Props> = ({
  deal,
  handleUpdate,
  handleMutate,
  vendorUsers = null
}: Props) => {
  if (deal === null) return null;
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  return (
    <div className={Styles.NavigationBlock}>
      <div className={Styles.IconWrapper}>
        <RiCalendarLine className={Styles.Icon} />
      </div>
      <div className={Styles.NavigationContent}>
        <div>
          <h4 className={Styles.Title}>
            立会日程を入力してください
            {deal.dealType === 'repair' && !deal.conductFieldSurvey && (
              <span className={Styles.OptionalLabel}>任意</span>
            )}
          </h4>
          <div className={Styles.Schedule}>
            {deal.inspectionDate != null ? (
              <>立会日時：{formatDate(deal.inspectionDate, true)}</>
            ) : (
              <>登録されていません</>
            )}
          </div>
        </div>
        <div className={Styles.DetailLinkBlock}>
          <Button
            className={Styles.DetailLinkButton}
            onClick={handleOpen}
            variant="normal"
          >
            立会日程を入力
          </Button>
          <ScheduleInputModal
            deal={deal}
            handleClose={handleClose}
            handleMutate={handleMutate}
            handleUpdate={handleUpdate}
            isOpen={isOpen}
            vendorUsers={vendorUsers}
          />
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/deals/[id]/billing_rentees/edit/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (id: number): string => `/deals/${id}/billing_rentees/edit`;

const Billings: NextPage = () => (
  <>
    <NskHead title="入居者への請求明細" />
    <Component />
  </>
);

export default Billings;

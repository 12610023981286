import React from 'react';
import { Button, Flex, Grid, RiAddBoxLine } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './ConstructionForm.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { DealConstructionEdit } from '~/models/DealConstruction';

type Props = {
  type: 'add' | 'edit' | 'add_modal';
  onClickSubmit: (reInput: boolean) => void;
};

export const ConstructionFormFooter: React.FC<Props> = ({
  type,
  onClickSubmit
}: Props) => {
  const { watch } = useFormContext<DealConstructionEdit>();
  const getButtonLabel = React.useCallback(() => {
    if (type === 'add') return '追加';
    if (type === 'edit') return '更新';
    return '続けて追加';
  }, [type]);
  const totalAmount = watch('totalAmount');
  return (
    <Flex>
      <Grid margin="8px" width="50%">
        {type === 'add' || type === 'edit' ? (
          <div className={Styles.TotalAmountView}>
            ¥{toThousandSeparator(totalAmount)}
          </div>
        ) : (
          <Button
            onClick={(): void => onClickSubmit(false)}
            variant="secondary"
            width="fill"
          >
            追加
          </Button>
        )}
      </Grid>
      <Grid alignItems="center" margin="8px" width="50%">
        <Button
          onClick={(): void => onClickSubmit(type === 'add_modal')}
          startIcon={<RiAddBoxLine />}
          variant={type === 'add_modal' ? 'primary' : 'secondary'}
          width="fill"
        >
          {getButtonLabel()}
        </Button>
      </Grid>
    </Flex>
  );
};
